export default [
  {
    path: '/home',
    meta: { title: 'Home' },
    component: () => import('@/pages/home/newsletters/NewslettersPage.vue')
  },
  {
    path: '/home/create',
    meta: { title: 'New Newsletter' },
    component: () => import('@/pages/home/newsletters/NewsletterPage.vue')
  },
  {
    path: '/home/:id',
    meta: { title: 'Newsletter' },
    component: () => import('@/pages/home/newsletters/NewsletterPage.vue')
  },
  {
    path: '/home/:id/stats',
    meta: { title: 'Newsletter Stats' },
    component: () => import('@/pages/home/newsletters/NewsletterStatsPage.vue')
  },
  {
    path: '/home/:id/publish',
    meta: { title: 'Publish Newsletter' },
    component: () => import('@/pages/home/newsletters/NewsletterPublishPage.vue')
  },
  {
    path: '/home/setup/intro',
    meta: { title: 'Invite Introduction' },
    component: () => import('@/pages/home/setup/IntroPage.vue')
  },
  {
    path: '/home/setup/emails/add',
    meta: { title: 'Add Emails' },
    component: () => import('@/pages/home/setup/EmailsAddPage.vue')
  },
  {
    path: '/home/setup/links/intro',
    meta: { title: 'Invite Page Introduction' },
    component: () => import('@/pages/home/setup/LinkIntroPage.vue')
  },
  {
    path: '/home/setup/links/setup',
    meta: { title: 'Setup Link Details' },
    component: () => import('@/pages/home/setup/LinkSetupPage.vue')
  },
  {
    path: '/home/setup/links/share',
    meta: { title: 'Setup Link Share' },
    component: () => import('@/pages/home/setup/LinkSharePage.vue')
  },
];
