<template>
  <ion-app>
    <ion-menu content-id="main-content" type="overlay" side="end" :swipe-gesture="false">
      <ion-content>
        <ion-list id="inbox-list">
          <ion-list-header>Settings</ion-list-header>

          <ion-menu-toggle auto-hide="false" v-for="(p, i) in appPages" :key="i">
            <ion-item @click="selectedIndex = i" router-direction="root" :router-link="p.url" lines="none" detail="false"
              class="hydrated" :class="{ selected: selectedIndex === i }">
              <ion-icon aria-hidden="true" slot="start" :icon="p.icon"></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
              <ion-badge slot="end" color="tertiary" v-if="notificationsCount && p.title === 'Manage Group'">{{ notificationsCount }}</ion-badge>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

        <div class="padding-x-16">
          <h6>Invite people to join your newsletter by sharing your special link:</h6>
          <a :href="`${defaultGroupCode}`" target="_blank">{{ defaultGroupCode }}</a>
        </div>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content" :animated="false" :swipe-gesture="false"></ion-router-outlet>

  </ion-app>
</template>

<script>
import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonBadge,
  IonRouterOutlet,
} from '@ionic/vue';
import { ref } from 'vue';
import { homeOutline, peopleOutline, personCircleOutline, ribbonOutline } from 'ionicons/icons';
import { mapGetters } from 'vuex';

export default {
  components: {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonBadge,
    IonRouterOutlet,
  },

  data() {
    return {
      homeOutline,
      peopleOutline,
      personCircleOutline,
      selectedIndex: ref(0),
      appPages: [
        {
          title: 'Home',
          url: '/home',
          icon: homeOutline,
        },
        {
          title: 'Account',
          url: '/account/profile',
          icon: personCircleOutline,
        },
        {
          title: 'Members',
          url: '/account/group',
          icon: peopleOutline,
        },
        {
          title: 'Premium',
          url: '/account/premium',
          icon: ribbonOutline,
        },
      ],
      user: {
        groups: []
      }
    };
  },

  computed: {
    ...mapGetters(["authUser", "group", "notificationsCount"]),

    defaultGroupCode(){
      if(this.group.code)
        return `${process.env.VUE_APP_WEB}/join/${this.group.code}`
      return `n/a`
    },
  },

  mounted() {
    if(this.authUser.id)
      this.fetchUser()

    let path = this.$route.path
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex((page) => page.url.toLowerCase() === path.toLowerCase());
    }
  },

  methods: {
    async fetchUser() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/users/me`)
        .then((response) => {
          this.user = response.data.user
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
</style>
