export default {
  authLoggedIn(state) {
    if (state.authUser.id && state.authToken.token) {
      // Check to see if the authToken is in JWT format (has at least 1 period)
      if (state.authToken.token.indexOf('.') < 0) {
        return false
      }

      return true;
    }
    return false;
  },

  authUser(state) {
    return state.authUser;
  },

  authToken(state) {
    return state.authToken;
  },

  group(state) {
    return state.group;
  },
};
