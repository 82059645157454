import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import axios from "axios";
import VueAxios from "vue-axios";
import BaseInput from "@/components/base/BaseInput.vue";
import BaseFieldDisplay from "@/components/base/BaseFieldDisplay.vue";

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Custom Theme */
import "./theme/custom.css"; // Import custom CSS

// Vuex Store
import store from './store/index.js';

// Firebase
import { initializeApp } from 'firebase/app';
import * as analytics from "@/util/analytics"
import * as remoteConfig from "@/util/remoteConfig"

// PWA Elements for Camera
import { defineCustomElements } from '@ionic/pwa-elements/loader';

// Firebase Initialization
initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEAUSREMENT_ID,
})

const app = createApp(App)
  .use(IonicVue, { 
    mode: "ios"
  })
  .use(router)
  .use(store)
  .use(VueAxios, axios);

app.component("base-input", BaseInput);
app.component("base-field-display", BaseFieldDisplay);

app.config.globalProperties.$analytics = analytics;
app.config.globalProperties.$remoteConfig = remoteConfig;

defineCustomElements(window);

router.isReady().then(() => {
  app.mount('#app');
});