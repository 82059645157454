import navigationMutations from "./mutations.js";
import navigationActions from "./actions.js";
import navigationGetters from "./getters.js";

export default {
  namespaced: false,
  state() {
    return {
      keyboardVisible: false
    };
  },
  mutations: navigationMutations,
  actions: navigationActions,
  getters: navigationGetters,
};
