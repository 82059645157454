export default [
  {
    path: '/account/profile',
    meta: { title: 'Profile' },
    component: () => import('@/pages/account/ProfilePage.vue')
  },
  {
    path: '/account/profile/edit',
    meta: { title: 'Edit Profile' },
    component: () => import('@/pages/account/ProfileEditPage.vue')
  },
  {
    path: '/account/password/edit',
    meta: { title: 'Edit Password' },
    component: () => import('@/pages/account/PasswordEditPage.vue')
  },
  {
    path: '/account/group',
    meta: { title: 'Manage Group' },
    component: () => import('@/pages/account/GroupPage.vue')
  },
  {
    path: '/account/group/edit',
    meta: { title: 'Edit Invite Page' },
    component: () => import('@/pages/account/GroupEditPage.vue')
  },
  {
    path: '/account/premium',
    meta: { title: 'Premium' },
    component: () => import('@/pages/account/PremiumPage.vue')
  },
  {
    path: '/account/premium/success',
    meta: { title: 'Success' },
    component: () => import('@/pages/account/SuccessPage.vue')
  },
];
