import { FirebaseRemoteConfig } from '@capacitor-firebase/remote-config';

async function activate() {
  await FirebaseRemoteConfig.activate();
}

async function fetchAndActivate() {
  await FirebaseRemoteConfig.fetchAndActivate();
}

async function fetchConfig() {
  await FirebaseRemoteConfig.fetchConfig({
    minimumFetchIntervalInSeconds: 1200,
  });
}

async function getBoolean(key) {
  const { value } = await FirebaseRemoteConfig.getBoolean({
    key: key,
  });

  console.log(`getBoolean ${key} - ${value}`)

  return value;
}

async function getNumber(key) {
  const { value } = await FirebaseRemoteConfig.getNumber({
    key: key,
  });
  return value;
}

async function getString(key) {
  const { value } = await FirebaseRemoteConfig.getString({
    key: key,
  });

  console.log(`getString ${key} - ${value}`)

  return value;
}

export {
  activate,
  fetchAndActivate,
  fetchConfig,
  getBoolean,
  getNumber,
  getString,
};