export default [
  {
    path: '/public/posts/:code',
    meta: { title: 'Hellokiin - Post' },
    component: () => import('@/pages/public/PostPage.vue')
  },
  {
    path: '/public/newsletters/:code',
    meta: { title: 'HelloKiin - Newsletter' },
    component: () => import('@/pages/public/NewsletterPage.vue')
  },
  {
    path: '/join/:code',
    meta: { title: 'HelloKiin - Join a Group' },
    component: () => import('@/pages/public/JoinGroupPage.vue')
  },
  {
    path: '/leave/:code',
    meta: { title: 'HelloKiin - Leave a Group' },
    component: () => import('@/pages/public/LeaveGroupPage.vue')
  },
  {
    path: '/public/gift/:code',
    meta: { title: 'HelloKiin - Gift Premium' },
    component: () => import('@/pages/public/GiftPage.vue')
  },
  {
    path: '/public/thankyou',
    meta: { title: 'HelloKiin - Thank You' },
    component: () => import('@/pages/public/ThankYouPage.vue')
  },
  {
    path: '/public/verify/user',
    meta: { title: 'HelloKiin - Verify User' },
    component: () => import('@/pages/public/VerifyUserPage.vue')
  },
  {
    path: '/public/verify/member',
    meta: { title: 'HelloKiin - Verify Member' },
    component: () => import('@/pages/public/VerifyMemberPage.vue')
  },
  {
    path: '/public/accept',
    meta: { title: 'HelloKiin - Accept' },
    component: () => import('@/pages/public/AcceptPage.vue')
  },
];