import { FirebaseAnalytics } from '@capacitor-firebase/analytics';

/**
 * Platform: Web/Android/iOS
 * Sets the user ID property.
 * @param userId - unique identifier of a user
 * @returns void
 * https://firebase.google.com/docs/analytics/userid
 */
function setUserId(userId) {
  if (process.env.VUE_APP_ANALYTICS_DEBUG)
    console.log(`analyticsSetUserId ${userId}`)

  if (process.env.VUE_APP_ANALYTICS_TRACK) {
    FirebaseAnalytics.setUserId({
      userId: userId,
    });
  }
}


/**
 * Platform: Web/Android/iOS
 * Sets a user property to a given value.
 * @param params - property name and value to set
 * @returns void
 * https://firebase.google.com/docs/analytics/user-properties
 */
function setUserProperty(name, value) {
  if (process.env.VUE_APP_ANALYTICS_TRACK) {
    FirebaseAnalytics.setUserProperty({
      key: name,
      value: value,
    });
  }
}


/**
 * Platform: Android/iOS
 * Retrieves the app instance id from the service.
 * @param none
 * @returns instanceId - individual instance id value
 * https://firebase.google.com/docs/analytics/user-properties
 */
function getAppInstanceId() {
  FirebaseAnalytics.getAppInstanceId();
}


/**
 * Platform: Android/iOS
 * Sets the current screen name, which specifies the current visual context in your app.
 * @param params - name of the current screen to track
 *        nameOverride - name of the screen class to override
 * @returns instanceId - individual instance id value
 * https://firebase.google.com/docs/analytics/screenviews
 */
function setScreenName(name, nameOverride) {
  if (process.env.VUE_APP_ANALYTICS_TRACK) {
    FirebaseAnalytics.setCurrentScreen({
      screenName: name,
      screenClassOverride: nameOverride,
    });
  }
}


/**
 * Platform: Web/Android/iOS
 * Clears all analytics data for this app from the device and resets the app instance id.
 * @param none
 * @returns void
 */
function reset() {
  // FirebaseAnalytics.reset();
  FirebaseAnalytics.resetAnalyticsData();
}


/**
 * Platform: Web/Android/iOS
 * Logs an app event.
 * @param name - name of the event to log
 * @param params - key/value pairs of properties (25 maximum per event)
 * 
 * @returns void
 */
function logEvent(params) {
  if (process.env.VUE_APP_ANALYTICS_DEBUG)
    console.log(`logEvent - ${JSON.stringify(params)}`)

  if (process.env.VUE_APP_ANALYTICS_TRACK) {
    FirebaseAnalytics.logEvent({
      name: params.name,
      params: (params.params) ? params.params : {}
    });
  }
}


/**
 * Platform: Web/Android/iOS
 * Sets whether analytics collection is enabled for this app on this device.
 * @param enabled - enabled - boolean true/false
 * @returns void
 */
function setCollectionEnabled(enabled) {
  FirebaseAnalytics.setEnabled({
    enabled: enabled,
  });
}


/**
 * Platform: Web/Android/iOS
 * Is enabled or not
 *
 * @returns boolean
 */
async function isEnabled() {
  const { enabled } = await FirebaseAnalytics.isEnabled();
  return enabled;
}



/**
 * Platform: Web/Android/iOS
 * Sets the duration of inactivity that terminates the current session.
 * @param duration - duration in seconds (default - 18000)
 * @returns void
 */
function setSessionTimeoutDuration(duration) {
  FirebaseAnalytics.setSessionTimeoutDuration({
    duration: duration,
  });
}




export {
  setUserId,
  setUserProperty,
  getAppInstanceId,
  setScreenName,
  reset,
  logEvent,
  setCollectionEnabled,
  isEnabled,
  setSessionTimeoutDuration
};
