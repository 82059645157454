export default {
  setAuthUser(state, payload) {
    state.authUser = payload;
  },

  setAuthToken(state, payload) {
    state.authToken = payload;
  },

  setGroup(state, payload) {
    state.group = payload;
  },
};
