<template>
  <ion-item lines="none" class="ion-no-padding">
    <!-- Label -->
    <ion-label position="stacked" v-if="labelText"> {{ labelText }} </ion-label>

    <!-- Slot for Input -->
    <slot />
  </ion-item>
</template>

<script>
import { IonItem, IonLabel } from "@ionic/vue"

export default {
  props: {
    labelText: {
      type: String,
    }
  },
  components: {
    IonItem,
    IonLabel
  }
}
</script>

<style scoped>
ion-label {
  font-size: 16px !important;
  font-weight: 600;
  padding-bottom: 4px;
}
</style>