import { createRouter, createWebHistory } from '@ionic/vue-router';
import accountRoutes from "./account";
import authRoutes from "./auth";
import homeRoutes from "./home";
import publicRoutes from "./public";

import store from "../store/index.js";
import axios from "axios";

import * as analytics from "@/util/analytics"

const routes = [
  {
    path: '',
    redirect: '/home'
  },
  ...accountRoutes,
  ...authRoutes,
  ...homeRoutes,
  ...publicRoutes,
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // If the route has a title, set it as the page title of the document/page
  document.title = (to.meta.title) ? to.meta.title : "HelloKiin"

  // Analytics tracking
  analytics.logEvent({
    name: "screen_view",
    params: { screen_name: document.title },
  })

  // Set authorization token on axios if it exists
  if (store.getters.authLoggedIn) {
    axios.defaults.headers.common = {
      Authorization: `bearer ${store.getters.authToken.token}`,
    };
  }

  // Guard to see that user cannot access pages unless they're logged in
  if (!store.getters.authLoggedIn && to.path.substring(0, 6) !== "/start" && to.path.substring(0, 5) !== "/join" && to.path.substring(0, 6) !== "/leave" && to.path.substring(0, 7) !== "/public") {
    next({ path: `/start`, query: { redirect: to.path } });
  }

  // Else just go
  else next();

  // next();
});

export default router
