export default [
  {
    path: '/start',
    meta: { title: 'Welcome to HelloKiin' },
    component: () => import('@/pages/auth/SplashPage.vue')
  },
  {
    path: '/start/login',
    meta: { title: 'Login' },
    component: () => import('@/pages/auth/LoginPage.vue')
  },
  {
    path: '/start/signup',
    meta: { title: 'Signup' },
    component: () => import('@/pages/auth/SignupPage.vue')
  },
  {
    path: '/start/password/request',
    meta: { title: 'Password Request' },
    component: () => import('@/pages/auth/RequestPasswordPage.vue')
  },
  {
    path: '/start/password/reset',
    meta: { title: 'Password Reset' },
    component: () => import('@/pages/auth/ResetPasswordPage.vue')
  },
  {
    path: '/start/logout',
    meta: { title: 'Logout' },
    component: () => import('@/pages/auth/LogoutPage.vue')
  },
];