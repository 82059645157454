export default {
  authLogin(context, payload) {
    context.commit("setAuthUser", payload.user);
    context.commit("setAuthToken", payload.token);
  },

  authLogout(context) {
    context.commit("setAuthUser", {});
    context.commit("setAuthToken", {});
  },

  setAuthUser(context, user){
    context.commit("setAuthUser", user);
  },

  setGroup(context, group){
    context.commit("setGroup", group);
  }
};
